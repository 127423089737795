/* Imports
================================================================================ */
import React, { FC, useState } from 'react';
import {
  ForgeAutocomplete,
  ForgeButton,
  ForgeIcon,
  ForgeIconButton,
  ForgeTextField,
  ForgeToolbar,
  ForgeTooltip,
} from '@tylertech/forge-react';
import { IAutocompleteOption, IListItemComponent } from '@tylertech/forge';
import * as Helpers from '../helpers';
import * as Types from '../types';
import airbrake from 'common/airbrake';
import { useApprovalSettingsContext } from '../contexts/ApprovalSettingsContext';
import ApproversTable from './ApproversTable';
import { UsersCatalogSearchResult, UsersCatalogSearchResults } from 'common/types/users/catalogUsers';
import { fetchUsersForAutocomplete } from 'common/core/approvals';
import { Approver } from 'common/types/approvals';



/* Component
================================================================================ */
const AddApproversModal: FC<Types.AddApproversModalAcceptedProps> = (props: Types.AddApproversModalAcceptedProps) => {
  const {
    state: { users },
    actions: { saveNewApprovers },
    getTranslateMethod
  } = useApprovalSettingsContext();
  const translate = getTranslateMethod('sections.approvers.add_approver');
  const topLevelTranslate = getTranslateMethod('actions');

  const [searchText] = useState<string>();
  const [catalogUsers, setCatalogUsers] = useState<UsersCatalogSearchResults[]>([]);

  const [saveIsDisabled, setSaveIsDisabled] = useState<boolean>(true);
  const [tableIsHidden, setTableIsHidden] = useState<boolean>(true);
  const [usersToAddDisplay, setUsersToAddDisplay] = useState<Approver[]>([]);
  const [usersToAddRecord, setUsersToAddRecord] = useState<Approver[]>([]);

  const fetchUserListForAutocomplete = (userSearch: string): Promise<IAutocompleteOption<string>[]> => {
    if (userSearch === '') {
        return Promise.resolve([]);
    }

    return fetchUsersForAutocomplete(userSearch).then((response: any) => {
        const results = response.results;
        return response;
        }).
        then((userResults: UsersCatalogSearchResult) => {
            // need to filter results to remove users that are already approvers, and users already selected and in table
            let filteredResults = userResults.results.filter((searchResult => !users.some((existingApprover) => searchResult.user?.email === existingApprover.email)));
            filteredResults = filteredResults.filter((filteredUser) => !usersToAddDisplay.some((userInTableAlready) => filteredUser.user?.email === userInTableAlready.email));
            setCatalogUsers(filteredResults);
            return filteredResults.map((result: UsersCatalogSearchResults) => {
                return {value: result.user?.screen_name, label: result.user?.email};
            });
        }).
        catch((err: any) => {
            airbrake.notify({
                err,
                context: {component: 'AddApproversModal'}
            });
        });
    };

  // occurs when selecting an option from the forge autocomplete dropdown
  const onAddUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    // we only want to add the user if they aren't already in usersToAddDisplay
    const found = usersToAddDisplay.find((usr) => (usr.email === event.target.value));
    if (!found) {
        // find user from catalog users
        const selectedUser = catalogUsers.find((u) => (u.user?.email === event.target.value));
        if (selectedUser && selectedUser.user && selectedUser.user.screen_name && selectedUser.user.email) {
            const approverFromSelectedUser: Approver = {
                userName: selectedUser?.user?.screen_name,
                email: selectedUser?.user?.email,
                role: selectedUser?.user?.role_name,
                can_review_public: false,
                can_review_internal: false,
                uid: selectedUser?.user?.id
            };

            const currentUsers = usersToAddDisplay.concat(approverFromSelectedUser);
            setUsersToAddDisplay(currentUsers);
            setUsersToAddRecord(currentUsers);

            setSaveIsDisabled(true); // users added, by default, have 0 workflows selected
        }

        setTableIsHidden(false);
    }
  };

  const onRemoveUserFromAddList = (row: Types.Approvals.Approver) => {
    const remainingUsers = usersToAddDisplay.filter((u) => u.email !== row.email);
    setUsersToAddDisplay(remainingUsers);
    setUsersToAddRecord(remainingUsers);

    // recheck save button enable/disable
    if (remainingUsers.length === 0) {
        setSaveIsDisabled(true);
        setTableIsHidden(true);
    } else {
      if (Helpers.anyApproverHasNoWorkflowSelected(remainingUsers)) {
        setSaveIsDisabled(true);
      } else {
        setSaveIsDisabled(false);
      }
    }
  };

  // When a checkbox is checked or unchecked, we must re-evaluate whether the save button is enabled or disabled.
  // Do this by finding the record (user) that had the state changed, and pass the user list with updated states to
  // function that determines whether save button is enabled or disabled.
  const onCheckboxChanged = (e: any) => {
    const userEmail = e.currentTarget.getAttribute('data-email');
    const audience = e.currentTarget.getAttribute('data-audience');
    const chkd = e.target.checked;

    let findUserFromCatalogResults;
    for (let i = 0; i < usersToAddDisplay.length; i++) {
        if (usersToAddDisplay[i].email === userEmail) {
            findUserFromCatalogResults = usersToAddDisplay[i];
        }
    }

    if (findUserFromCatalogResults) {
        const foundUser = findUserFromCatalogResults;
        // grab whether the checkboxes are checked or unchecked
        if (audience === 'public') {
            foundUser.can_review_public = chkd;
        } else if (audience === 'internal') {
            foundUser.can_review_internal = chkd;
        }

        const usersToAddCopy = usersToAddRecord; // make a copy to mutate
        const usersToAddCopyWithUserRemoved = usersToAddCopy.filter((u) => (u.email !== userEmail));
        const newUserList = usersToAddCopyWithUserRemoved.concat(foundUser);

        setUsersToAddRecord(newUserList);
    }


    if (Helpers.anyApproverHasNoWorkflowSelected(usersToAddRecord)) {
        setSaveIsDisabled(true);
    } else {
        setSaveIsDisabled(false);
    }
  };

  const onSaveNewApprovers = () => {
    saveNewApprovers(usersToAddRecord);
    onCloseDialog();
  };

  // reset dialog to starting state
  const onCloseDialog = () => {
    setUsersToAddDisplay([]);
    setUsersToAddRecord([]);
    setTableIsHidden(true);
    setSaveIsDisabled(true);
    props.closeDialogHandler();
  };

  // For generating the options in the Forge autocomplete dropdown component
  const optionBuilder = (option: {type: string, label: string, value: string}, filterText: string, listItem: IListItemComponent) => {
    return `<forge-list-item two-line>
                <forge-icon slot="leading" name="person"></forge-icon>
                <span slot="title">${option.value}</span>
                <span slot="subtitle">${option.label}</span>
            </forge-list-item>`;
  };

  return (
    <div>
      <ForgeToolbar no-border={true}>
        <span className="forge-typography--headline5" slot="start">{translate('title')}</span>
        <ForgeIconButton data-testid="add-approvers-modal-close-button" slot="end">
            <button data-testid='close-add-approvers-modal-button' onClick={onCloseDialog} type="button">
            <ForgeIcon name="close" />
            </button>
        </ForgeIconButton>
      </ForgeToolbar>
      <section className="forge-dialog__body dialog-body-override" id="add-approver-autocomplete">
        <div className="forge-typography--body1 body1-typography-override">{translate('subtitle')}</div>
        <ForgeAutocomplete
          allowUnmatched={true}
          filterOnFocus={false}
          multiple={false}
          filter={fetchUserListForAutocomplete}
          onSelect={onAddUser}
          optionBuilder={optionBuilder}
          value={searchText ? {value: searchText, label: searchText} : undefined}>
            <ForgeTextField style={{width:400}}>
                <input data-testid="add-approvers-modal-user-search-input" type="text" id="user" />
                <label htmlFor="user">{translate('search_users')}</label>
                <ForgeIconButton slot="trailing">
                    <button data-testid='add-approvers-modal-autocomplete-clear-content' type="button" data-forge-autocomplete-clear aria-label="Clear the selection">
                        <ForgeIcon name="close"/>
                    </button>
                </ForgeIconButton>
                <ForgeIcon slot="trailing" data-forge-dropdown-icon name="arrow_drop_down" />
            </ForgeTextField>
        </ForgeAutocomplete>
        <div className="table-container">
          {!tableIsHidden && <ApproversTable tableType={Types.ApproversTableType.SETTINGS_MODAL_ADD} users={usersToAddDisplay} onRemoveUser={onRemoveUserFromAddList} onCheckboxChanged={onCheckboxChanged}>
              </ApproversTable>}
        </div>
      </section>
      <footer className="forge-dialog__footer">
        <ForgeButton type="outlined" style={{ marginRight: 16 }}>
            <button data-testid="add-approvers-modal-cancel-button" type="button" onClick={onCloseDialog}>{topLevelTranslate('cancel')}</button>
        </ForgeButton>
        <ForgeButton type="raised">
            {saveIsDisabled && <ForgeTooltip>{translate('save_requirements')}</ForgeTooltip>}
            <button data-testid="add-approvers-modal-save-button" type="button" onClick={onSaveNewApprovers} forge-dialog-focus="true" disabled={saveIsDisabled}>{topLevelTranslate('save')}</button>
        </ForgeButton>
      </footer>
    </div>
  );
};

export default AddApproversModal;
